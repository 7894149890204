body {
  font-family: 'Open Sans',Arial,Helvetica,sans-serif !important;
  text-shadow: 0 0 0;
  font-size: 1em;
}

h1, h2, h3, h4, hr {
  color: hsl(33, 35%, 32%) !important;
}

.appContainer {
  position: relative;
  top: 50px;
  margin-top: 50px;
  width: 100%;
}

.appContainer #content.page {
  padding: 0 2%;
  background-color: #fff;
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto 200px auto;
  height: 100%;
  display: block;
  clear: both;
}

nav {
  height: 50px;
  background: linear-gradient(90deg,hsl(33, 36%, 50%) 0,hsl(11, 41%, 26%) 50%);
  box-shadow: 0px 2px 4px #646464;
  top: 0;
  width: 100%;
  z-index: 1000;
  padding: 0 !important;
}

nav img {
  height: 30px;
  margin: 12px;
}

nav .link.active {
  background: linear-gradient(0deg,hsl(33.1, 20.9%, 53.9%) 0,hsla(11.1, 40.9%, 25.9%, 0) 20%);
}

nav .link:not(.active):hover {
  background: linear-gradient(0deg,hsl(33.1, 20.9%, 53.9%, .3) 0,hsla(11.1, 40.9%, 25.9%, 0) 20%);
}

nav .link {
  color: white;
  text-decoration: none;
  display: inline;
  cursor: pointer;
  padding: 12px 2px;
  border-radius: 2px;
}

nav #ngeHamburger .dropdown-toggle::after {
  display: none !important;
}

.loading_wrapper {
  text-align: center;
  margin: 5em auto;
}

.pagination .page-item .page-link {
  cursor: pointer;
}

.lynx-table {
  border: 2px solid whitesmoke !important;
}

.lynx-table thead th {
  color: hsl(33, 35%, 32%);
  padding: 1rem;
  vertical-align: middle !important;
  border: none;
}

.lynx-table tbody td {
  padding: 1rem;
  vertical-align: middle !important;
  border: none;
}

.lynx-table.table-striped tr:nth-child(odd) td, .lynx-table.table-striped > tbody > tr:nth-of-type(2n+1) > * {
  background-color: whitesmoke !important;
  --bs-table-bg-type: whitesmoke !important;
}

.lynx-table.table-striped tr:nth-child(even) td {
  background-color: white !important;
  --bs-table-bg-type: white !important;
}

.not-allowed {
  cursor: not-allowed !important;
}

.accordion-button {
  background-color: white !important;
  box-shadow: 0px 2px 4px #646464 !important;
  border: 1px solid #198754 !important;
}

.nav-tabs .nav-link.active {
  background: hsl(210, 5%, 94%) !important;
}

.breadCrumbs {
  margin-bottom: 2em;
  height: 2em;
}

.breadCrumbs .breadCrumb {
  display: inline-block;
  text-transform: uppercase;
  font-size: .8em;
}

.breadCrumbs .breadCrumb:after {
  content: '\0000BB';
  margin: 0 .5em;
}

.breadCrumbs .breadCrumb:last-child:after {
  content: '';
}


.drop-zone {
  border: 1px dashed #aaa; /* Light gray dashed border */
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  background-color: white; 
  cursor: default; /* Default cursor for the drop zone itself */
  height: 300px; 
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.drop-zone p {
  font-size: 18px; 
  font-weight: normal; 
  margin-bottom: 10px;
}

/* The "Browse files" button with light blue background */
.browse-files {
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  padding: 6px 12px;
  background-color: white;
  color: black; 
  border: 1px solid #ccc; 
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
}

/* Hover effect for only the .browse-files */
.browse-files:hover {
  background-color: #87cefa;
  color: white;
}

/* Only show the pointer cursor when hovering over the Browse files button */
.browse-files:hover {
  cursor: pointer;
}


.file-list {
  margin-top: 15px;
  text-align: left;
}

.file-item {
  margin: 5px 0;
}

th.sortableColumn {
  cursor: pointer;
  user-select: none;
}

th.sortableColumn::after {
  color: inherit;
  content: "";
}

th.sortableColumn.desc::after {
  color: inherit;
  content: "▾";
}

th.sortableColumn.asc::after {
  color: inherit;
  content: "▴";
}